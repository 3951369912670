import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './url-prefix.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const UrlPrefixDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const urlPrefixEntity = useAppSelector(state => state.urlPrefix.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="urlPrefixDetailsHeading">
          <Translate contentKey="shortUrlApp.urlPrefix.detail.title">UrlPrefix</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{urlPrefixEntity.id}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="shortUrlApp.urlPrefix.code">Code</Translate>
            </span>
            <UncontrolledTooltip target="code">
              <Translate contentKey="shortUrlApp.urlPrefix.help.code" />
            </UncontrolledTooltip>
          </dt>
          <dd>{urlPrefixEntity.code}</dd>
          <dt>
            <span id="prefix">
              <Translate contentKey="shortUrlApp.urlPrefix.prefix">Prefix</Translate>
            </span>
            <UncontrolledTooltip target="prefix">
              <Translate contentKey="shortUrlApp.urlPrefix.help.prefix" />
            </UncontrolledTooltip>
          </dt>
          <dd>{urlPrefixEntity.prefix}</dd>
          <dt>
            <span id="remark">
              <Translate contentKey="shortUrlApp.urlPrefix.remark">Remark</Translate>
            </span>
            <UncontrolledTooltip target="remark">
              <Translate contentKey="shortUrlApp.urlPrefix.help.remark" />
            </UncontrolledTooltip>
          </dt>
          <dd>{urlPrefixEntity.remark}</dd>
          <dt>
            <span id="version">
              <Translate contentKey="shortUrlApp.urlPrefix.version">Version</Translate>
            </span>
            <UncontrolledTooltip target="version">
              <Translate contentKey="shortUrlApp.urlPrefix.help.version" />
            </UncontrolledTooltip>
          </dt>
          <dd>{urlPrefixEntity.version}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="shortUrlApp.urlPrefix.active">Active</Translate>
            </span>
            <UncontrolledTooltip target="active">
              <Translate contentKey="shortUrlApp.urlPrefix.help.active" />
            </UncontrolledTooltip>
          </dt>
          <dd>{urlPrefixEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="deleted">
              <Translate contentKey="shortUrlApp.urlPrefix.deleted">Deleted</Translate>
            </span>
            <UncontrolledTooltip target="deleted">
              <Translate contentKey="shortUrlApp.urlPrefix.help.deleted" />
            </UncontrolledTooltip>
          </dt>
          <dd>{urlPrefixEntity.deleted ? 'true' : 'false'}</dd>
        </dl>
        <Button tag={Link} to="/url-prefix" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/url-prefix/${urlPrefixEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default UrlPrefixDetail;
