import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './shorten-req-batch.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const ShortenReqBatchDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const shortenReqBatchEntity = useAppSelector(state => state.shortenReqBatch.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="shortenReqBatchDetailsHeading">
          <Translate contentKey="shortUrlApp.shortenReqBatch.detail.title">ShortenReqBatch</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{shortenReqBatchEntity.id}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="shortUrlApp.shortenReqBatch.code">Code</Translate>
            </span>
            <UncontrolledTooltip target="code">
              <Translate contentKey="shortUrlApp.shortenReqBatch.help.code" />
            </UncontrolledTooltip>
          </dt>
          <dd>{shortenReqBatchEntity.code}</dd>
          <dt>
            <span id="appKeyCode">
              <Translate contentKey="shortUrlApp.shortenReqBatch.appKeyCode">App Key Code</Translate>
            </span>
            <UncontrolledTooltip target="appKeyCode">
              <Translate contentKey="shortUrlApp.shortenReqBatch.help.appKeyCode" />
            </UncontrolledTooltip>
          </dt>
          <dd>{shortenReqBatchEntity.appKeyCode}</dd>
        </dl>
        <Button tag={Link} to="/shorten-req-batch" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/shorten-req-batch/${shortenReqBatchEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ShortenReqBatchDetail;
