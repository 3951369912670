import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CallShortenRecord from './call-shorten-record';
import CallShortenRecordDetail from './call-shorten-record-detail';
import CallShortenRecordUpdate from './call-shorten-record-update';
import CallShortenRecordDeleteDialog from './call-shorten-record-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CallShortenRecordUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CallShortenRecordUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CallShortenRecordDetail} />
      <ErrorBoundaryRoute path={match.url} component={CallShortenRecord} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={CallShortenRecordDeleteDialog} />
  </>
);

export default Routes;
