import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ShortenReqBatch from './shorten-req-batch';
import ShortenReqBatchDetail from './shorten-req-batch-detail';
import ShortenReqBatchUpdate from './shorten-req-batch-update';
import ShortenReqBatchDeleteDialog from './shorten-req-batch-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ShortenReqBatchUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ShortenReqBatchUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ShortenReqBatchDetail} />
      <ErrorBoundaryRoute path={match.url} component={ShortenReqBatch} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ShortenReqBatchDeleteDialog} />
  </>
);

export default Routes;
