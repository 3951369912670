import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText, UncontrolledTooltip } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity, updateEntity, createEntity, reset } from './url-prefix.reducer';
import { IUrlPrefix } from 'app/shared/model/url-prefix.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const UrlPrefixUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const urlPrefixEntity = useAppSelector(state => state.urlPrefix.entity);
  const loading = useAppSelector(state => state.urlPrefix.loading);
  const updating = useAppSelector(state => state.urlPrefix.updating);
  const updateSuccess = useAppSelector(state => state.urlPrefix.updateSuccess);
  const handleClose = () => {
    props.history.push('/url-prefix' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...urlPrefixEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...urlPrefixEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="shortUrlApp.urlPrefix.home.createOrEditLabel" data-cy="UrlPrefixCreateUpdateHeading">
            <Translate contentKey="shortUrlApp.urlPrefix.home.createOrEditLabel">Create or edit a UrlPrefix</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="url-prefix-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('shortUrlApp.urlPrefix.code')}
                id="url-prefix-code"
                name="code"
                data-cy="code"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 32, message: translate('entity.validation.maxlength', { max: 32 }) },
                }}
              />
              <UncontrolledTooltip target="codeLabel">
                <Translate contentKey="shortUrlApp.urlPrefix.help.code" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('shortUrlApp.urlPrefix.prefix')}
                id="url-prefix-prefix"
                name="prefix"
                data-cy="prefix"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 32, message: translate('entity.validation.maxlength', { max: 32 }) },
                }}
              />
              <UncontrolledTooltip target="prefixLabel">
                <Translate contentKey="shortUrlApp.urlPrefix.help.prefix" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('shortUrlApp.urlPrefix.remark')}
                id="url-prefix-remark"
                name="remark"
                data-cy="remark"
                type="text"
              />
              <UncontrolledTooltip target="remarkLabel">
                <Translate contentKey="shortUrlApp.urlPrefix.help.remark" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('shortUrlApp.urlPrefix.version')}
                id="url-prefix-version"
                name="version"
                data-cy="version"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <UncontrolledTooltip target="versionLabel">
                <Translate contentKey="shortUrlApp.urlPrefix.help.version" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('shortUrlApp.urlPrefix.active')}
                id="url-prefix-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <UncontrolledTooltip target="activeLabel">
                <Translate contentKey="shortUrlApp.urlPrefix.help.active" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('shortUrlApp.urlPrefix.deleted')}
                id="url-prefix-deleted"
                name="deleted"
                data-cy="deleted"
                check
                type="checkbox"
              />
              <UncontrolledTooltip target="deletedLabel">
                <Translate contentKey="shortUrlApp.urlPrefix.help.deleted" />
              </UncontrolledTooltip>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/url-prefix" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default UrlPrefixUpdate;
