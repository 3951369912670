import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './call-short-url-record.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const CallShortUrlRecordDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const callShortUrlRecordEntity = useAppSelector(state => state.callShortUrlRecord.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="callShortUrlRecordDetailsHeading">
          <Translate contentKey="shortUrlApp.callShortUrlRecord.detail.title">CallShortUrlRecord</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{callShortUrlRecordEntity.id}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="shortUrlApp.callShortUrlRecord.code">Code</Translate>
            </span>
            <UncontrolledTooltip target="code">
              <Translate contentKey="shortUrlApp.callShortUrlRecord.help.code" />
            </UncontrolledTooltip>
          </dt>
          <dd>{callShortUrlRecordEntity.code}</dd>
          <dt>
            <span id="shortCode">
              <Translate contentKey="shortUrlApp.callShortUrlRecord.shortCode">Short Code</Translate>
            </span>
            <UncontrolledTooltip target="shortCode">
              <Translate contentKey="shortUrlApp.callShortUrlRecord.help.shortCode" />
            </UncontrolledTooltip>
          </dt>
          <dd>{callShortUrlRecordEntity.shortCode}</dd>
          <dt>
            <span id="urlLong">
              <Translate contentKey="shortUrlApp.callShortUrlRecord.urlLong">Url Long</Translate>
            </span>
            <UncontrolledTooltip target="urlLong">
              <Translate contentKey="shortUrlApp.callShortUrlRecord.help.urlLong" />
            </UncontrolledTooltip>
          </dt>
          <dd>{callShortUrlRecordEntity.urlLong}</dd>
          <dt>
            <span id="reqTime">
              <Translate contentKey="shortUrlApp.callShortUrlRecord.reqTime">Req Time</Translate>
            </span>
            <UncontrolledTooltip target="reqTime">
              <Translate contentKey="shortUrlApp.callShortUrlRecord.help.reqTime" />
            </UncontrolledTooltip>
          </dt>
          <dd>
            {callShortUrlRecordEntity.reqTime ? (
              <TextFormat value={callShortUrlRecordEntity.reqTime} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="ip">
              <Translate contentKey="shortUrlApp.callShortUrlRecord.ip">Ip</Translate>
            </span>
            <UncontrolledTooltip target="ip">
              <Translate contentKey="shortUrlApp.callShortUrlRecord.help.ip" />
            </UncontrolledTooltip>
          </dt>
          <dd>{callShortUrlRecordEntity.ip}</dd>
          <dt>
            <span id="userAgent">
              <Translate contentKey="shortUrlApp.callShortUrlRecord.userAgent">User Agent</Translate>
            </span>
            <UncontrolledTooltip target="userAgent">
              <Translate contentKey="shortUrlApp.callShortUrlRecord.help.userAgent" />
            </UncontrolledTooltip>
          </dt>
          <dd>{callShortUrlRecordEntity.userAgent}</dd>
          <dt>
            <span id="shortenReqBatchCode">
              <Translate contentKey="shortUrlApp.callShortUrlRecord.shortenReqBatchCode">Shorten Req Batch Code</Translate>
            </span>
            <UncontrolledTooltip target="shortenReqBatchCode">
              <Translate contentKey="shortUrlApp.callShortUrlRecord.help.shortenReqBatchCode" />
            </UncontrolledTooltip>
          </dt>
          <dd>{callShortUrlRecordEntity.shortenReqBatchCode}</dd>
        </dl>
        <Button tag={Link} to="/call-short-url-record" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/call-short-url-record/${callShortUrlRecordEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CallShortUrlRecordDetail;
