import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './call-shorten-record.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const CallShortenRecordDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const callShortenRecordEntity = useAppSelector(state => state.callShortenRecord.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="callShortenRecordDetailsHeading">
          <Translate contentKey="shortUrlApp.callShortenRecord.detail.title">CallShortenRecord</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{callShortenRecordEntity.id}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="shortUrlApp.callShortenRecord.code">Code</Translate>
            </span>
            <UncontrolledTooltip target="code">
              <Translate contentKey="shortUrlApp.callShortenRecord.help.code" />
            </UncontrolledTooltip>
          </dt>
          <dd>{callShortenRecordEntity.code}</dd>
          <dt>
            <span id="urlLong">
              <Translate contentKey="shortUrlApp.callShortenRecord.urlLong">Url Long</Translate>
            </span>
            <UncontrolledTooltip target="urlLong">
              <Translate contentKey="shortUrlApp.callShortenRecord.help.urlLong" />
            </UncontrolledTooltip>
          </dt>
          <dd>{callShortenRecordEntity.urlLong}</dd>
          <dt>
            <span id="appKey">
              <Translate contentKey="shortUrlApp.callShortenRecord.appKey">App Key</Translate>
            </span>
            <UncontrolledTooltip target="appKey">
              <Translate contentKey="shortUrlApp.callShortenRecord.help.appKey" />
            </UncontrolledTooltip>
          </dt>
          <dd>{callShortenRecordEntity.appKey}</dd>
          <dt>
            <span id="reqTime">
              <Translate contentKey="shortUrlApp.callShortenRecord.reqTime">Req Time</Translate>
            </span>
            <UncontrolledTooltip target="reqTime">
              <Translate contentKey="shortUrlApp.callShortenRecord.help.reqTime" />
            </UncontrolledTooltip>
          </dt>
          <dd>
            {callShortenRecordEntity.reqTime ? (
              <TextFormat value={callShortenRecordEntity.reqTime} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="ip">
              <Translate contentKey="shortUrlApp.callShortenRecord.ip">Ip</Translate>
            </span>
            <UncontrolledTooltip target="ip">
              <Translate contentKey="shortUrlApp.callShortenRecord.help.ip" />
            </UncontrolledTooltip>
          </dt>
          <dd>{callShortenRecordEntity.ip}</dd>
          <dt>
            <span id="shortenReqBatchCode">
              <Translate contentKey="shortUrlApp.callShortenRecord.shortenReqBatchCode">Shorten Req Batch Code</Translate>
            </span>
            <UncontrolledTooltip target="shortenReqBatchCode">
              <Translate contentKey="shortUrlApp.callShortenRecord.help.shortenReqBatchCode" />
            </UncontrolledTooltip>
          </dt>
          <dd>{callShortenRecordEntity.shortenReqBatchCode}</dd>
        </dl>
        <Button tag={Link} to="/call-shorten-record" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/call-shorten-record/${callShortenRecordEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CallShortenRecordDetail;
