import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText, UncontrolledTooltip } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity, updateEntity, createEntity, reset } from './white-list.reducer';
import { IWhiteList } from 'app/shared/model/white-list.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const WhiteListUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const whiteListEntity = useAppSelector(state => state.whiteList.entity);
  const loading = useAppSelector(state => state.whiteList.loading);
  const updating = useAppSelector(state => state.whiteList.updating);
  const updateSuccess = useAppSelector(state => state.whiteList.updateSuccess);
  const handleClose = () => {
    props.history.push('/white-list' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...whiteListEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...whiteListEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="shortUrlApp.whiteList.home.createOrEditLabel" data-cy="WhiteListCreateUpdateHeading">
            <Translate contentKey="shortUrlApp.whiteList.home.createOrEditLabel">Create or edit a WhiteList</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="white-list-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('shortUrlApp.whiteList.code')}
                id="white-list-code"
                name="code"
                data-cy="code"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 32, message: translate('entity.validation.maxlength', { max: 32 }) },
                }}
              />
              <UncontrolledTooltip target="codeLabel">
                <Translate contentKey="shortUrlApp.whiteList.help.code" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('shortUrlApp.whiteList.groupName')}
                id="white-list-groupName"
                name="groupName"
                data-cy="groupName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 32, message: translate('entity.validation.maxlength', { max: 32 }) },
                }}
              />
              <UncontrolledTooltip target="groupNameLabel">
                <Translate contentKey="shortUrlApp.whiteList.help.groupName" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('shortUrlApp.whiteList.groupDesc')}
                id="white-list-groupDesc"
                name="groupDesc"
                data-cy="groupDesc"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <UncontrolledTooltip target="groupDescLabel">
                <Translate contentKey="shortUrlApp.whiteList.help.groupDesc" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('shortUrlApp.whiteList.ip')}
                id="white-list-ip"
                name="ip"
                data-cy="ip"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 1024, message: translate('entity.validation.maxlength', { max: 1024 }) },
                }}
              />
              <UncontrolledTooltip target="ipLabel">
                <Translate contentKey="shortUrlApp.whiteList.help.ip" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('shortUrlApp.whiteList.appKeyCode')}
                id="white-list-appKeyCode"
                name="appKeyCode"
                data-cy="appKeyCode"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 32, message: translate('entity.validation.maxlength', { max: 32 }) },
                }}
              />
              <UncontrolledTooltip target="appKeyCodeLabel">
                <Translate contentKey="shortUrlApp.whiteList.help.appKeyCode" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('shortUrlApp.whiteList.remark')}
                id="white-list-remark"
                name="remark"
                data-cy="remark"
                type="text"
              />
              <UncontrolledTooltip target="remarkLabel">
                <Translate contentKey="shortUrlApp.whiteList.help.remark" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('shortUrlApp.whiteList.version')}
                id="white-list-version"
                name="version"
                data-cy="version"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <UncontrolledTooltip target="versionLabel">
                <Translate contentKey="shortUrlApp.whiteList.help.version" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('shortUrlApp.whiteList.active')}
                id="white-list-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <UncontrolledTooltip target="activeLabel">
                <Translate contentKey="shortUrlApp.whiteList.help.active" />
              </UncontrolledTooltip>
              <ValidatedField
                label={translate('shortUrlApp.whiteList.deleted')}
                id="white-list-deleted"
                name="deleted"
                data-cy="deleted"
                check
                type="checkbox"
              />
              <UncontrolledTooltip target="deletedLabel">
                <Translate contentKey="shortUrlApp.whiteList.help.deleted" />
              </UncontrolledTooltip>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/white-list" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default WhiteListUpdate;
