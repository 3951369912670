import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';

export const EntitiesMenu = props => (
  <NavDropdown
    icon="th-list"
    name={translate('global.menu.entities.main')}
    id="entity-menu"
    data-cy="entity"
    style={{ maxHeight: '80vh', overflow: 'auto' }}
  >
    <>{/* to avoid warnings when empty */}</>
    <MenuItem icon="asterisk" to="/url-mapping">
      <Translate contentKey="global.menu.entities.urlMapping" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/call-shorten-record">
      <Translate contentKey="global.menu.entities.callShortenRecord" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/call-short-url-record">
      <Translate contentKey="global.menu.entities.callShortUrlRecord" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/shorten-req-batch">
      <Translate contentKey="global.menu.entities.shortenReqBatch" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/app-key">
      <Translate contentKey="global.menu.entities.appKey" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/white-list">
      <Translate contentKey="global.menu.entities.whiteList" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/support-domain">
      <Translate contentKey="global.menu.entities.supportDomain" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/url-prefix">
      <Translate contentKey="global.menu.entities.urlPrefix" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/id-backup">
      <Translate contentKey="global.menu.entities.idBackup" />
    </MenuItem>
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </NavDropdown>
);
