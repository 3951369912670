import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import IdBackup from './id-backup';
import IdBackupDetail from './id-backup-detail';
import IdBackupUpdate from './id-backup-update';
import IdBackupDeleteDialog from './id-backup-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={IdBackupUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={IdBackupUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={IdBackupDetail} />
      <ErrorBoundaryRoute path={match.url} component={IdBackup} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={IdBackupDeleteDialog} />
  </>
);

export default Routes;
