import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import AppKey from './app-key';
import AppKeyDetail from './app-key-detail';
import AppKeyUpdate from './app-key-update';
import AppKeyDeleteDialog from './app-key-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={AppKeyUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={AppKeyUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={AppKeyDetail} />
      <ErrorBoundaryRoute path={match.url} component={AppKey} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={AppKeyDeleteDialog} />
  </>
);

export default Routes;
