import dayjs from 'dayjs';

export interface ICallShortenRecord {
  id?: number;
  code?: string;
  urlLong?: string;
  appKey?: string;
  reqTime?: string;
  ip?: string | null;
  shortenReqBatchCode?: string;
}

export const defaultValue: Readonly<ICallShortenRecord> = {};
