export interface IWhiteList {
  id?: number;
  code?: string;
  groupName?: string;
  groupDesc?: string;
  ip?: string;
  appKeyCode?: string;
  remark?: string | null;
  version?: number;
  active?: boolean;
  deleted?: boolean;
}

export const defaultValue: Readonly<IWhiteList> = {
  active: false,
  deleted: false,
};
