import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CallShortUrlRecord from './call-short-url-record';
import CallShortUrlRecordDetail from './call-short-url-record-detail';
import CallShortUrlRecordUpdate from './call-short-url-record-update';
import CallShortUrlRecordDeleteDialog from './call-short-url-record-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CallShortUrlRecordUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CallShortUrlRecordUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CallShortUrlRecordDetail} />
      <ErrorBoundaryRoute path={match.url} component={CallShortUrlRecord} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={CallShortUrlRecordDeleteDialog} />
  </>
);

export default Routes;
