import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './white-list.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const WhiteListDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const whiteListEntity = useAppSelector(state => state.whiteList.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="whiteListDetailsHeading">
          <Translate contentKey="shortUrlApp.whiteList.detail.title">WhiteList</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{whiteListEntity.id}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="shortUrlApp.whiteList.code">Code</Translate>
            </span>
            <UncontrolledTooltip target="code">
              <Translate contentKey="shortUrlApp.whiteList.help.code" />
            </UncontrolledTooltip>
          </dt>
          <dd>{whiteListEntity.code}</dd>
          <dt>
            <span id="groupName">
              <Translate contentKey="shortUrlApp.whiteList.groupName">Group Name</Translate>
            </span>
            <UncontrolledTooltip target="groupName">
              <Translate contentKey="shortUrlApp.whiteList.help.groupName" />
            </UncontrolledTooltip>
          </dt>
          <dd>{whiteListEntity.groupName}</dd>
          <dt>
            <span id="groupDesc">
              <Translate contentKey="shortUrlApp.whiteList.groupDesc">Group Desc</Translate>
            </span>
            <UncontrolledTooltip target="groupDesc">
              <Translate contentKey="shortUrlApp.whiteList.help.groupDesc" />
            </UncontrolledTooltip>
          </dt>
          <dd>{whiteListEntity.groupDesc}</dd>
          <dt>
            <span id="ip">
              <Translate contentKey="shortUrlApp.whiteList.ip">Ip</Translate>
            </span>
            <UncontrolledTooltip target="ip">
              <Translate contentKey="shortUrlApp.whiteList.help.ip" />
            </UncontrolledTooltip>
          </dt>
          <dd>{whiteListEntity.ip}</dd>
          <dt>
            <span id="appKeyCode">
              <Translate contentKey="shortUrlApp.whiteList.appKeyCode">App Key Code</Translate>
            </span>
            <UncontrolledTooltip target="appKeyCode">
              <Translate contentKey="shortUrlApp.whiteList.help.appKeyCode" />
            </UncontrolledTooltip>
          </dt>
          <dd>{whiteListEntity.appKeyCode}</dd>
          <dt>
            <span id="remark">
              <Translate contentKey="shortUrlApp.whiteList.remark">Remark</Translate>
            </span>
            <UncontrolledTooltip target="remark">
              <Translate contentKey="shortUrlApp.whiteList.help.remark" />
            </UncontrolledTooltip>
          </dt>
          <dd>{whiteListEntity.remark}</dd>
          <dt>
            <span id="version">
              <Translate contentKey="shortUrlApp.whiteList.version">Version</Translate>
            </span>
            <UncontrolledTooltip target="version">
              <Translate contentKey="shortUrlApp.whiteList.help.version" />
            </UncontrolledTooltip>
          </dt>
          <dd>{whiteListEntity.version}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="shortUrlApp.whiteList.active">Active</Translate>
            </span>
            <UncontrolledTooltip target="active">
              <Translate contentKey="shortUrlApp.whiteList.help.active" />
            </UncontrolledTooltip>
          </dt>
          <dd>{whiteListEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="deleted">
              <Translate contentKey="shortUrlApp.whiteList.deleted">Deleted</Translate>
            </span>
            <UncontrolledTooltip target="deleted">
              <Translate contentKey="shortUrlApp.whiteList.help.deleted" />
            </UncontrolledTooltip>
          </dt>
          <dd>{whiteListEntity.deleted ? 'true' : 'false'}</dd>
        </dl>
        <Button tag={Link} to="/white-list" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/white-list/${whiteListEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default WhiteListDetail;
