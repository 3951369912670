import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './url-mapping.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const UrlMappingDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const urlMappingEntity = useAppSelector(state => state.urlMapping.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="urlMappingDetailsHeading">
          <Translate contentKey="shortUrlApp.urlMapping.detail.title">UrlMapping</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{urlMappingEntity.id}</dd>
          <dt>
            <span id="urlLong">
              <Translate contentKey="shortUrlApp.urlMapping.urlLong">Url Long</Translate>
            </span>
            <UncontrolledTooltip target="urlLong">
              <Translate contentKey="shortUrlApp.urlMapping.help.urlLong" />
            </UncontrolledTooltip>
          </dt>
          <dd>{urlMappingEntity.urlLong}</dd>
          <dt>
            <span id="urlShort">
              <Translate contentKey="shortUrlApp.urlMapping.urlShort">Url Short</Translate>
            </span>
            <UncontrolledTooltip target="urlShort">
              <Translate contentKey="shortUrlApp.urlMapping.help.urlShort" />
            </UncontrolledTooltip>
          </dt>
          <dd>{urlMappingEntity.urlShort}</dd>
          <dt>
            <span id="shortCode">
              <Translate contentKey="shortUrlApp.urlMapping.shortCode">Short Code</Translate>
            </span>
            <UncontrolledTooltip target="shortCode">
              <Translate contentKey="shortUrlApp.urlMapping.help.shortCode" />
            </UncontrolledTooltip>
          </dt>
          <dd>{urlMappingEntity.shortCode}</dd>
          <dt>
            <span id="shortenReqBatchCode">
              <Translate contentKey="shortUrlApp.urlMapping.shortenReqBatchCode">Shorten Req Batch Code</Translate>
            </span>
            <UncontrolledTooltip target="shortenReqBatchCode">
              <Translate contentKey="shortUrlApp.urlMapping.help.shortenReqBatchCode" />
            </UncontrolledTooltip>
          </dt>
          <dd>{urlMappingEntity.shortenReqBatchCode}</dd>
        </dl>
        <Button tag={Link} to="/url-mapping" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/url-mapping/${urlMappingEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default UrlMappingDetail;
