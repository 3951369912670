import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import UrlPrefix from './url-prefix';
import UrlPrefixDetail from './url-prefix-detail';
import UrlPrefixUpdate from './url-prefix-update';
import UrlPrefixDeleteDialog from './url-prefix-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={UrlPrefixUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={UrlPrefixUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={UrlPrefixDetail} />
      <ErrorBoundaryRoute path={match.url} component={UrlPrefix} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={UrlPrefixDeleteDialog} />
  </>
);

export default Routes;
