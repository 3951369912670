import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './app-key.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const AppKeyDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const appKeyEntity = useAppSelector(state => state.appKey.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="appKeyDetailsHeading">
          <Translate contentKey="shortUrlApp.appKey.detail.title">AppKey</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{appKeyEntity.id}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="shortUrlApp.appKey.code">Code</Translate>
            </span>
            <UncontrolledTooltip target="code">
              <Translate contentKey="shortUrlApp.appKey.help.code" />
            </UncontrolledTooltip>
          </dt>
          <dd>{appKeyEntity.code}</dd>
          <dt>
            <span id="userName">
              <Translate contentKey="shortUrlApp.appKey.userName">User Name</Translate>
            </span>
            <UncontrolledTooltip target="userName">
              <Translate contentKey="shortUrlApp.appKey.help.userName" />
            </UncontrolledTooltip>
          </dt>
          <dd>{appKeyEntity.userName}</dd>
          <dt>
            <span id="userSource">
              <Translate contentKey="shortUrlApp.appKey.userSource">User Source</Translate>
            </span>
            <UncontrolledTooltip target="userSource">
              <Translate contentKey="shortUrlApp.appKey.help.userSource" />
            </UncontrolledTooltip>
          </dt>
          <dd>{appKeyEntity.userSource}</dd>
          <dt>
            <span id="key">
              <Translate contentKey="shortUrlApp.appKey.key">Key</Translate>
            </span>
            <UncontrolledTooltip target="key">
              <Translate contentKey="shortUrlApp.appKey.help.key" />
            </UncontrolledTooltip>
          </dt>
          <dd>{appKeyEntity.key}</dd>
          <dt>
            <span id="whiteListEnabled">
              <Translate contentKey="shortUrlApp.appKey.whiteListEnabled">White List Enabled</Translate>
            </span>
            <UncontrolledTooltip target="whiteListEnabled">
              <Translate contentKey="shortUrlApp.appKey.help.whiteListEnabled" />
            </UncontrolledTooltip>
          </dt>
          <dd>{appKeyEntity.whiteListEnabled ? 'true' : 'false'}</dd>
          <dt>
            <span id="prefix">
              <Translate contentKey="shortUrlApp.appKey.prefix">Prefix</Translate>
            </span>
            <UncontrolledTooltip target="prefix">
              <Translate contentKey="shortUrlApp.appKey.help.prefix" />
            </UncontrolledTooltip>
          </dt>
          <dd>{appKeyEntity.prefix}</dd>
          <dt>
            <span id="remark">
              <Translate contentKey="shortUrlApp.appKey.remark">Remark</Translate>
            </span>
            <UncontrolledTooltip target="remark">
              <Translate contentKey="shortUrlApp.appKey.help.remark" />
            </UncontrolledTooltip>
          </dt>
          <dd>{appKeyEntity.remark}</dd>
          <dt>
            <span id="version">
              <Translate contentKey="shortUrlApp.appKey.version">Version</Translate>
            </span>
            <UncontrolledTooltip target="version">
              <Translate contentKey="shortUrlApp.appKey.help.version" />
            </UncontrolledTooltip>
          </dt>
          <dd>{appKeyEntity.version}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="shortUrlApp.appKey.active">Active</Translate>
            </span>
            <UncontrolledTooltip target="active">
              <Translate contentKey="shortUrlApp.appKey.help.active" />
            </UncontrolledTooltip>
          </dt>
          <dd>{appKeyEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="deleted">
              <Translate contentKey="shortUrlApp.appKey.deleted">Deleted</Translate>
            </span>
            <UncontrolledTooltip target="deleted">
              <Translate contentKey="shortUrlApp.appKey.help.deleted" />
            </UncontrolledTooltip>
          </dt>
          <dd>{appKeyEntity.deleted ? 'true' : 'false'}</dd>
        </dl>
        <Button tag={Link} to="/app-key" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/app-key/${appKeyEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default AppKeyDetail;
