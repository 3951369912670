import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale from './locale';
import authentication from './authentication';
import applicationProfile from './application-profile';

import administration from 'app/modules/administration/administration.reducer';
import userManagement from 'app/modules/administration/user-management/user-management.reducer';
import register from 'app/modules/account/register/register.reducer';
import activate from 'app/modules/account/activate/activate.reducer';
import password from 'app/modules/account/password/password.reducer';
import settings from 'app/modules/account/settings/settings.reducer';
import passwordReset from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import urlMapping from 'app/entities/url-mapping/url-mapping.reducer';
// prettier-ignore
import callShortenRecord from 'app/entities/call-shorten-record/call-shorten-record.reducer';
// prettier-ignore
import callShortUrlRecord from 'app/entities/call-short-url-record/call-short-url-record.reducer';
// prettier-ignore
import shortenReqBatch from 'app/entities/shorten-req-batch/shorten-req-batch.reducer';
// prettier-ignore
import appKey from 'app/entities/app-key/app-key.reducer';
// prettier-ignore
import whiteList from 'app/entities/white-list/white-list.reducer';
// prettier-ignore
import supportDomain from 'app/entities/support-domain/support-domain.reducer';
// prettier-ignore
import urlPrefix from 'app/entities/url-prefix/url-prefix.reducer';
// prettier-ignore
import idBackup from 'app/entities/id-backup/id-backup.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const rootReducer = {
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  urlMapping,
  callShortenRecord,
  callShortUrlRecord,
  shortenReqBatch,
  appKey,
  whiteList,
  supportDomain,
  urlPrefix,
  idBackup,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
};

export default rootReducer;
