import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import WhiteList from './white-list';
import WhiteListDetail from './white-list-detail';
import WhiteListUpdate from './white-list-update';
import WhiteListDeleteDialog from './white-list-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={WhiteListUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={WhiteListUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={WhiteListDetail} />
      <ErrorBoundaryRoute path={match.url} component={WhiteList} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={WhiteListDeleteDialog} />
  </>
);

export default Routes;
