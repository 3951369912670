import React from 'react';
import { Switch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import UrlMapping from './url-mapping';
import CallShortenRecord from './call-shorten-record';
import CallShortUrlRecord from './call-short-url-record';
import ShortenReqBatch from './shorten-req-batch';
import AppKey from './app-key';
import WhiteList from './white-list';
import SupportDomain from './support-domain';
import UrlPrefix from './url-prefix';
import IdBackup from './id-backup';
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}url-mapping`} component={UrlMapping} />
      <ErrorBoundaryRoute path={`${match.url}call-shorten-record`} component={CallShortenRecord} />
      <ErrorBoundaryRoute path={`${match.url}call-short-url-record`} component={CallShortUrlRecord} />
      <ErrorBoundaryRoute path={`${match.url}shorten-req-batch`} component={ShortenReqBatch} />
      <ErrorBoundaryRoute path={`${match.url}app-key`} component={AppKey} />
      <ErrorBoundaryRoute path={`${match.url}white-list`} component={WhiteList} />
      <ErrorBoundaryRoute path={`${match.url}support-domain`} component={SupportDomain} />
      <ErrorBoundaryRoute path={`${match.url}url-prefix`} component={UrlPrefix} />
      <ErrorBoundaryRoute path={`${match.url}id-backup`} component={IdBackup} />
      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
