import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './support-domain.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const SupportDomainDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const supportDomainEntity = useAppSelector(state => state.supportDomain.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="supportDomainDetailsHeading">
          <Translate contentKey="shortUrlApp.supportDomain.detail.title">SupportDomain</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{supportDomainEntity.id}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="shortUrlApp.supportDomain.code">Code</Translate>
            </span>
            <UncontrolledTooltip target="code">
              <Translate contentKey="shortUrlApp.supportDomain.help.code" />
            </UncontrolledTooltip>
          </dt>
          <dd>{supportDomainEntity.code}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="shortUrlApp.supportDomain.name">Name</Translate>
            </span>
            <UncontrolledTooltip target="name">
              <Translate contentKey="shortUrlApp.supportDomain.help.name" />
            </UncontrolledTooltip>
          </dt>
          <dd>{supportDomainEntity.name}</dd>
          <dt>
            <span id="appKeyCode">
              <Translate contentKey="shortUrlApp.supportDomain.appKeyCode">App Key Code</Translate>
            </span>
            <UncontrolledTooltip target="appKeyCode">
              <Translate contentKey="shortUrlApp.supportDomain.help.appKeyCode" />
            </UncontrolledTooltip>
          </dt>
          <dd>{supportDomainEntity.appKeyCode}</dd>
          <dt>
            <span id="remark">
              <Translate contentKey="shortUrlApp.supportDomain.remark">Remark</Translate>
            </span>
            <UncontrolledTooltip target="remark">
              <Translate contentKey="shortUrlApp.supportDomain.help.remark" />
            </UncontrolledTooltip>
          </dt>
          <dd>{supportDomainEntity.remark}</dd>
          <dt>
            <span id="version">
              <Translate contentKey="shortUrlApp.supportDomain.version">Version</Translate>
            </span>
            <UncontrolledTooltip target="version">
              <Translate contentKey="shortUrlApp.supportDomain.help.version" />
            </UncontrolledTooltip>
          </dt>
          <dd>{supportDomainEntity.version}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="shortUrlApp.supportDomain.active">Active</Translate>
            </span>
            <UncontrolledTooltip target="active">
              <Translate contentKey="shortUrlApp.supportDomain.help.active" />
            </UncontrolledTooltip>
          </dt>
          <dd>{supportDomainEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="deleted">
              <Translate contentKey="shortUrlApp.supportDomain.deleted">Deleted</Translate>
            </span>
            <UncontrolledTooltip target="deleted">
              <Translate contentKey="shortUrlApp.supportDomain.help.deleted" />
            </UncontrolledTooltip>
          </dt>
          <dd>{supportDomainEntity.deleted ? 'true' : 'false'}</dd>
        </dl>
        <Button tag={Link} to="/support-domain" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/support-domain/${supportDomainEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SupportDomainDetail;
