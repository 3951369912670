import dayjs from 'dayjs';

export interface ICallShortUrlRecord {
  id?: number;
  code?: string;
  shortCode?: string;
  urlLong?: string;
  reqTime?: string;
  ip?: string | null;
  userAgent?: string | null;
  shortenReqBatchCode?: string;
}

export const defaultValue: Readonly<ICallShortUrlRecord> = {};
