export interface IAppKey {
  id?: number;
  code?: string;
  userName?: string;
  userSource?: string;
  key?: string;
  whiteListEnabled?: boolean;
  prefix?: string | null;
  remark?: string | null;
  version?: number;
  active?: boolean;
  deleted?: boolean;
}

export const defaultValue: Readonly<IAppKey> = {
  whiteListEnabled: false,
  active: false,
  deleted: false,
};
