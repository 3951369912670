export interface IUrlPrefix {
  id?: number;
  code?: string;
  prefix?: string;
  remark?: string | null;
  version?: number;
  active?: boolean;
  deleted?: boolean;
}

export const defaultValue: Readonly<IUrlPrefix> = {
  active: false,
  deleted: false,
};
